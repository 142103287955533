var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "informationDetails commonF graphShadow paddingB20" },
    [
      _c("infoTable", { attrs: { tableData: _vm.tableData, num: 1 } }),
      _c(
        "div",
        { staticClass: "content wrapperS" },
        [
          _c("dir", { staticClass: "title" }, [_vm._v("消息内容：")]),
          _c("div", {
            staticClass: "txt",
            domProps: { innerHTML: _vm._s(this.viewData.msgContent) },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "goback" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go("-1")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }